import React from 'react'
import { hero } from './style.module.scss'

export const Hero = () => {
  return (
    <section className={hero}>
      <h1>Our Team</h1>
    </section>
  )
}
