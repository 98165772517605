import React from 'react'
import { Tab, Nav } from 'react-bootstrap'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import DATA from './constants'
import {
  members,
  members_wrap,
  members_tabs,
  members_link,
  members_list,
  members_item,
  members_pic,
  members_position,
  members_name,
} from './style.module.scss'

export const Members = () => {
  const data = useStaticQuery(graphql`
    {
      allTeamJson {
        edges {
          node {
            id
            slug
            name
            position
            office
            photo {
              id
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 262, height: 300, quality: 100, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)

  const team = data.allTeamJson.edges.sort(({ node: a }, { node: b }) => a.id - b.id)

  return (
    <section className={members}>
      <Tab.Container defaultActiveKey={DATA[0]}>
        <div className={members_wrap}>
          <Nav variant="pills" className={members_tabs}>
            {DATA.map((tab) => (
              <Nav.Item key={tab}>
                <Nav.Link as="button" className={members_link} eventKey={tab}>
                  {tab}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey={DATA[0]}>
            <div className={members_list}>
              {team.map(({ node: { slug, name, position, photo } }) =>
                slug ? (
                  <Link className={members_item} to={slug} key={name}>
                    <span className={members_pic}>
                      <GatsbyImage
                        image={photo.childImageSharp.gatsbyImageData}
                        alt={name}
                        style={{ display: 'block' }}
                      />
                    </span>
                    {position && <p className={members_position}>{position}</p>}
                    <p className={members_name}>{name}</p>
                  </Link>
                ) : (
                  <div key={name}>
                    <span className={members_pic}>
                      <GatsbyImage
                        image={photo.childImageSharp.gatsbyImageData}
                        alt={name}
                        style={{ display: 'block' }}
                      />
                    </span>
                    {position && <p className={members_position}>{position}</p>}
                    <p className={members_name}>{name}</p>
                  </div>
                )
              )}
            </div>
          </Tab.Pane>
          {DATA.filter(({ title }) => title !== 'All').map((tab) => (
            <Tab.Pane eventKey={tab} key={tab}>
              <div className={members_list}>
                {team
                  .filter(({ node: { office } }) => office === tab)
                  .map(({ node: { slug, name, position, photo } }) => {
                    return slug ? (
                      <Link className={members_item} to={slug} key={name}>
                        <span className={members_pic}>
                          <GatsbyImage
                            image={photo.childImageSharp.gatsbyImageData}
                            alt={name}
                            style={{ display: 'block' }}
                          />
                        </span>
                        {position && <p className={members_position}>{position}</p>}
                        <p className={members_name}>{name}</p>
                      </Link>
                    ) : (
                      <div key={name}>
                        <span className={members_pic}>
                          <GatsbyImage
                            image={photo.childImageSharp.gatsbyImageData}
                            alt={name}
                            style={{ display: 'block' }}
                          />
                        </span>
                        {position && <p className={members_position}>{position}</p>}
                        <p className={members_name}>{name}</p>
                      </div>
                    )
                  })}
              </div>
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </section>
  )
}
