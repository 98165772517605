import * as React from 'react'
import { Container } from 'react-bootstrap'
import Layout from '~components/Layout'
import SEO from '~components/seo'
import { Hero } from './components/Hero'
import { Members } from './components/Members'

export const Team = () => {
  return (
    <Layout>
      <Container>
        <SEO title="Our Team" />
        <Hero />
        <Members />
      </Container>
    </Layout>
  )
}
